import PropTypes from 'prop-types'
import React from 'react'

import CandlestickChart from './CandlestickChart'
import AreaChart from './AreaChart'
import barApi from '../../api/bar'

export default class PairChart extends React.Component {
  static propTypes = {
    pair: PropTypes.object,
    range: PropTypes.string,
    unit: PropTypes.number,
    limit: PropTypes.number,
    type: PropTypes.string,
    mode: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.state = { loading: true, data: [] }
    this.onRefresh = this.onRefresh.bind(this)
  }

  loadBar(pair, range, limit = 1000, showLoading = true) {
    if (showLoading) {
      this.setState({ ...this.state, loading: true })
    }
    barApi.getBar(pair.product_code, range, limit).then((bars) => {
      if (this._isMounted) {
        this.setState({ loading: false, data: bars })
      }
    })
  }

  onRefresh() {
    const { pair, range, limit } = this.props
    this.loadBar(pair, range, limit, false)
  }

  componentDidMount() {
    this._isMounted = true
    const { pair, range, limit } = this.props
    this.loadBar(pair, range, limit)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentWillReceiveProps(nextProps) {
    const { pair, range, limit } = nextProps

    if (this.props.range != range || this.props.limit != limit) {
      this.loadBar(pair, range, limit)
    }
  }

  render() {
    const periods = [
      { period: 25, color: '#d83835' },
      { period: 75, color: '#1e91d1' },
    ]
    const { pair, unit, range, mode } = this.props

    switch (this.props.type) {
      case 'candlestick':
        return (
          <CandlestickChart
            pair={pair}
            data={this.state.data}
            periods={periods}
            unit={unit}
            range={range}
            loading={this.state.loading}
            defaultChartRange={mode == 'pc' ? 60 : 30}
            onRefresh={this.onRefresh}
            refreshingRate={100}
            mode={mode}
          />
        )
      case 'area':
        return (
          <AreaChart
            pair={pair}
            data={this.state.data}
            periods={periods}
            unit={unit}
            range={range}
            loading={this.state.loading}
            onRefresh={this.onRefresh}
            refreshingRate={100}
          />
        )
      default:
        return null
    }
  }
}
